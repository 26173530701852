body {
  margin: 0;
  height: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --green_cassa: #C4D600;
  --blue_cassa: #3182CE;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

span.anticon {
  vertical-align: super;
}

.mySpinner {
  text-align: center;
  /* background: rgba(0, 0, 0, 0.05); */
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.ant-page-header {
  display: flex;
  flex-direction: column;
}

.ant-breadcrumb+.ant-page-header-heading {
  margin-top: 0 !important;
}

.ant-breadcrumb {
  /* padding-top: 20px !important; */
  margin: 32px 39px 2px 30px !important;

}

/* .content-wrapper{
  padding: 40px 40px 40px 40px;
} */

.rigth {
  padding-bottom: 20px;
  float: right !important;
}

.botton-save {
  padding-right: 40px;
}

.main-header {
  position: inherit !important;
}


.Upload-B {
  padding-right: 10px !important;
  float: rigth !important;
}

.ant-table-thead>tr>th {
  text-align: center !important;
}

.ant-table-tbody {
  text-align: center;
}


.padding-table {
  font-family: 'Poppins';
  padding: 40px 40px 40px 40px;
}

span[aria-label='paper-clip'] {
  display: block;
}

.Confirm-Modal-Title {
  padding-top: 15px;
}

#watermark {
  display: none !important;
}

.ant-input-number-input:hover {
  padding-right: 35px !important;
}

.ant-input-number-input {
  text-align: end !important;
}

/* .smart-element {
  width: inherit !important;
} */

/* .smart-splitter-item{
  width: auto !important;
} */

.right-table {
  position: sticky;
  right: 0px;
}

.left-table {
  position: sticky !important;
  left: 0px;
}

.left-table2 {
  position: sticky;
  left: 167px;
}

.left-table3 {
  position: sticky;
  left: 293px;
}

.center-table {
  text-align: center;
}

.refino {
  background: transparent !important;
  color: #727272 !important;
  min-width: 50px;
}

.crudoSaco {
  background:transparent !important;
  color: #727272 !important;
  min-width: 50px;

}

.blanco {
  background:transparent !important;
  color: #727272 !important;
  min-width: 50px;

}

.crudo {
  background:transparent !important;
  color: #727272 !important;
  min-width: 50px;

}

.melaza {
  background:transparent !important;
  color: #727272 !important;
  min-width: 50px;

}

.meses {
  background: yellowgreen !important;
}

.zafra {
  background: skyblue !important;
}

.ant-statistic-title {
  color: #fff ;
}

.gantt_cell {
  text-align: center !important;
}

.fc-past {
  background-color: #eee !important;
}

.gantt_bar_project .gantt_task_content {
  background-color: #483d8b !important;
  border: 1px solid #483d8b !important;
}

/* //Arriba */

.gantt_bar_task .gantt_task_content {
  background-color: #64b9df !important;
  border: 1px solid #64b9df !important;
}

/* Abajo */

.gantt_marker {
  background-color: forestgreen !important
}

.btn-primary {
  color: white !important;
  background-color: var(--blue_cassa) !important
}

.custom-labelRequired {
  color: red
}

.fc-center::first-letter {
  text-transform: uppercase;
}

.info-box {
  padding: 0px !important;
}

.info-box-icon {
  background: darkgreen;
  color: white;
}

.bg-yellow {
  background-color: transparent;
}

.info-box-content {
  background: #71a1c9;
  color: white;
  font-size: 14px;
  padding: 10px 10px 10px 10px !important;
}

.ant-collapse-header {
  background: #2C5282 !important;
  color: white !important;
}

.button-panel {
  float: right;
}

.card-green {
  background: green;
  color: white;
}

.card-darkgreen {
  background: darkgreen;
  color: white;
}

.aliear-tabla {
  text-align: center;
}

.small-font {
  font-size: 10px;
  margin-bottom: 0;
}

.info-box-text {
  padding-top: 35%;
}

.badge-confirmar {
  float: right;
  padding-left: 10px;
}

.ant-table-cell-fix-left {
  width: 100px;
}

.info-box bg-yellow {
  background: transparent;
}

.kiosko-subtitle {
  font-size: 30px;
}

.kiosko-title {
  font-size: 40px;
}

.input-aling-rigth {
  text-align: end !important;
}

.span-st {
  position: absolute;
  right: 35px;
  top: 34px;
  font-style: italic;
  text-transform: capitalize;
  /* opacity: 0.5; */
  display: block;
  font-size: 18px;
}

.card-booking {
  position: absolute;
  right: 35px;
  top: 195px;
  font-style: italic;
  text-transform: capitalize;
  /* opacity: 0.5; */
  display: block;
  font-size: 18px;
}

.btn-grande-confirmar {
  height: 94px !important;
  width: 210px !important;
  font-size: 30px !important;
}

.test {
  width: 1835px;
  height: 930px;
}

.ocultar {
  display: none !important;
}

.ant-page-header {
  /* background:#2C5282 !important; */
}

.ant-page-header-heading-title {
  color: #fff !important;
}

.ant-breadcrumb-link {
  color: #fff !important;
}

.ant-breadcrumb-separator {
  color: #fff !important;
}

.ant-breadcrumb a {
  color: #fff !important;
}

.ant-table table {
  font-size: 10px;
}

.ant-form-item-required {
  font-size: 10px !important;
  color: rgb(88 88 88 / 85%) !important;
}

.ant-form-vertical .ant-form-item-label>label {
  font-size: 10px !important;
  color: rgb(88 88 88 / 85%) !important;
}

.ant-tabs-tab ant-tabs-tab-active {
  color: #fff !important;
  font-weight: 500;
}

.ant-tabs-tab-active {
  background: #2C5282 !important;
  color: #fff !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
  font-weight: 500;
}

.fondo-blanco {
  background: #fff;
}

.fondo-gray {
  background: #f3f3f3a8;
}

.fondo-formulario {
  background: #dcdfe51f;
}

.ant-tree {
  background: #e9e9e9 !important;
}

/* .ant-steps-item-icon{
  background: #2C5282 !important;
}
.ant-steps-item-wait .ant-steps-item-icon{
  background: #fff !important;
} */

/* .ant-btn-primary {
  background: #2C5282 !important;
} */

.ant-btn-danger {
  color: #667387 !important;
  background: #ffffff !important;
  border-color: #667387 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-tabs-tab-active {
  padding: 10px !important;
}

.logo {
  max-width: 112px;
  margin: 0px 10px 10px 50px !important;
}

.ant-switch {
  color: rgba(0, 0, 0, 0.85) !important;
}

.padding-switch {
  margin: 0px 10px 0px 10px;
}

.margen-datos {
  margin: 15px 10px 10px 100px;
  font-size: 15px;
}

.ant-btn ant-btn-primary {
  color: white !important;
}

.white-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  position: static;
  width: 100%px;
  height: 50px;
  left: 0px;
  top: 0px;
  /* Base/Gray50 */
  background: #F9F9FB;
  /* Base/Gray300 */
  border: 1px solid #C6CCD5;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.text-nomi {
  position: static;
  width: 243px;
  height: 18px;
  left: calc(50% - 243px/2);
  top: 10px;
  /* Subtitle/SM Regular */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  /* Base/Gray500 */
  color: #718096;
  /* Inside Auto Layout */
  padding-left: 15px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}

.text-nomi-prod {
  position: static;
  height: 18px;
  left: calc(50% - 745px/2);
  top: 10px;
  /* Subtitle/SM Regular */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  /* Base/Gray600 */
  color: #667387;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}

/* .ant-steps-item-container :hover{
  padding-right: 50px;
} */


/* Estilos para las tablas */

.ant-table-filter-column-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #718096;
}
.ant-table-cell{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #718096 !important;
}


.ant-table-thead {
  background: #FFFFFF;
}

tr:nth-child(even) {
  background-color: #F9F9FB;
}

.ant-table-content {
  border: 1px solid #C6CCD5;
  border-radius: 5px 5px 0px 0px;
}

tbody {
  font-size: 12px !important;
}
.os-content{
  font-family: 'Poppins';
}
.brand-link{
  border-bottom:0px solid #fff !important
}
.brand-link{
  padding-top:50px
}
/* .nav-sidebar > .nav-item {
  margin: 0px 40px 0px 10px;
} */
.nav {
  font-size: 12px !important;
}

.editar{
  border: 0px !important;
    background: 0 !important;
}
.lista{
  border: 0px !important;
    background: 0 !important;
}
.ant-card-body{
  font-family: 'Poppins';

}
.green{
  background: #48BB78 !important;
  height: 44px;
    width: 270px;
    font-size:16px;
}


.c-cards{
  position: absolute;
  top: 0;
  margin-top: 130px;
}
.ant-page-header{
  font-family: 'Poppins';
  height: 100px;
}


.nav-con{
  margin: 10px 40px 10px 0px !important;
}
.user-margen{
  padding: 2px 45px 0px 15px;
}
.ant-breadcrumb{
  font-size: 9px !important;
}
.user-name{
  padding-top: 3px;
  font-family: 'Poppins';
color: #718096;
}
.dropdown-menu{
  font-family: 'Poppins';
  color: #718096;
}
.dashboard-1{
  background: #FFFFFF;
  flex: 0 0 22%;
  max-width: 22%;
  margin-right: 24px;
  /* height: 383px; */ 
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
/* margin: 0px 40px 0px 0px; */

}

.dashboard-1, .chart-dashboard   {
  margin-bottom: 20px;
}

@media (min-width: 360px) {
  .dashboard-1 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .chart-dashboard{
    flex: 0 0 100% !important;
  }
  .counter-title{
    font-size: large !important;
  }
}


@media (min-width: 650px) {
  .dashboard-1  {
    max-width: 100%;
    flex: 0 0 22%;
  }

  .chart-dashboard{
    flex: 0 0 70% !important;
  }
  .counter-title{
    font-size: large !important;
  }
}
@media (min-width: 768px) {
  .dashboard-1 {
    max-width: 100%;
    flex: 0 0 22% ;
  }
  .chart-dashboard{
    flex: 0 0 70% !important;
  }

  .counter-title{
    font-size: large !important;
  }
}



@media (min-width: 360px) {
  .counter {
    max-width: 100% !important;
    flex: 0 0 45% !important;
  }
}

@media (min-width: 768px) {
  .counter {
    max-width: 100% !important;
    flex: 0 0 30% !important;
  }
}

@media (min-width: 875px) {
  .counter {
    max-width: 100% !important;
    flex: 0 0 20% !important;
  }
}
.counter{
  margin-bottom: 20px !important;
  /* margin-right: auto !important; */
}
.div-center{
  display: flex;
  justify-content: space-between;
}



.title-dashboard{
  font-size: 18px;
  font-weight: bold;
  font-family: 'Poppins';
  color: rgba(113, 128, 150, 1)
}
.sub-title-dashboard{
  font-size: 9px;
  font-family: 'Poppins';
  color: rgba(113, 128, 150, 1)
}
.contenedor-dashboard-1{
  margin: 40px 21px 29px 25px;

}

.dashboard-img{
  width: 100%;

}

.chart-dashboard{
  /* WB/ White */
  flex: 0 0 70%;
  max-width: 70;
background: #FFFFFF;
/* Shadows/Base */
/* width: 794px;
height: 381px; */
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
border-radius: 8px;
}

.counter{
  background: #FFFFFF;
  flex: 0 0 25%;
  max-width: 22%;
/* margin-right: 25px; */
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
border-radius: 10px;
}
.counter-none{
     /* background: #FFFFFF; */
     flex: 0 0 25%;
     max-width: 22%;
     margin-right: 25px;
     /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1); */
     border-radius: 10px;
 }
.counter3{
  background: #FFFFFF;
  flex: 0 0 25%;
  max-width: 22%;
margin-right: 25px;
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
border-radius: 10px;
}
.counter4{
  background: #FFFFFF;
  flex: 0 0 25%;
  max-width: 22%;
margin-right: 25px;
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
border-radius: 10px;
}

.title-chart{
  font-family: 'Poppins';
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 27px;
/* identical to box height */

padding-left: 35px;
padding-top: 35px;

/* Primary/600 */

color: #3182CE;

}

.counter-button{
background: #3182CE !important;
border-radius: 5px !important;
width: 100% !important;
height: 55px !important;
font-family: 'Poppins';
color: white !important;
padding: 5px 10px !important;

}

.mg-r{
  margin-right: 35px !important;
}
.counter-content{
  color: #3182CE;
  margin: 15px 15px 10px 15px;
  font-family: 'Poppins';
}
.counter-number{
  color: #3182CE;
  margin: 0px 0px 5px 0px;

}
.counter-title{
  font-size: 24px;
  margin: 0px 0px 5px 0px;


}
.counter-sub-title{
  font-size: 12px;

}
.buttom{
  padding-bottom: 100px;
}
.form-login{
  background: #FFFFFF;
  font-family: 'Poppins';
/* width: 400px; */
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
border-radius: 10px;
}
.center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-login{
  height: 70px;

}

.btn-login{
  width: 100%;
}

.form{
  background: #E5E5E5;
      height: 100vh;

}

.form-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.formulario {
  flex: 0 0 auto;
}

.results {
  flex: 1 1 auto;
}

.img-login-fondo{
  height: 100vh;
    width: 100% !important;
}

.ant-col ant-form-item-label{
  font-family: 'Poppins'
}
.arrow-Rigth{
  float: RIGHT;
  PADDING: 10PX;
}
.arrow-Center{
  float: CENTER;
  PADDING: 10PX;
}
.arrow-Rigth-1{
  padding: 15PX !important;
  float: right;
}

.padding-logo-count{
  padding-top: 4px;
}

.margin-icon-table{
  margin:7px
}

.margin-icon-table-r{
float: right;
margin:10px
}

.login-title-left{
  margin:0px;

font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 36px;
line-height: 105%;
/* or 38px */
}

.login-subtitle-left{
  font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 130.5%;
}
.login-title2-left{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  margin: 48px 0px 0px 0px;

  /* identical to box height */
  
  display: flex;
  align-items: center;
  
}

.left-container{
  width: 250px;
  margin: 130px 32px 25px 170px;


}

.info{
  /* display: flex;
    align-items: center;
    justify-content: center; */
    color:white
}

.pa-log{
  padding-top: 20px;
  width: 150px;
  padding-left: 10px;


}

.horarios{
  display: flex;

}
.horario-item{
  display: flex;
    width: 170px;
}

.icon-dowload{
  float: right;
  padding: 0px 0px 10px 10px;
}

.flex-column{
  padding-bottom: 200px;
}
.site-statistic-demo-card{
  display:flex;
}
.title-pe-b{
  padding-bottom: 10px;
}

.plan-title{
  font-size:9px;
}

.loading{
  background:#fff;
  justify-content: center;
  display: flex;
}
#card-reportes>div.ant-card-body{
  display: flex;
  justify-content: center;
}

.logo-loading{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: white;
  position: fixed;
  bottom: 0;
}

#form-calendarModal>div{
  /* height: 60px; */
}

.third-level{
  padding-left: 20px;
}
.user-collapse{
  color: #fff;
  font-size: 17px;
}
.ant-tag-has-color {
width:155px;
}

.apk_styleTitle{
  /*position: absolute; */
  height: 100%;
  /* bottom: 0; */
  /* vertical-align: bottom; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  font-size: 15px;
  color: #f4f6f9;
}

.card-witdthMovil{
  width: 100% !important;
  align-items: center;
}

.cardBody {
  height: 200px;
}

.cardBody i {
  font-size: 17em;
  opacity: 0.2;
  padding-left: 15px;
}

.card-witdthMovil div i {
  font-size: 10em;
  opacity: 0.2;
  padding-left: 15px;
}

.btnKiosko {
  height: 76px !important;
  width: 240px !important;
  font-size: 3em !important;
  text-align: center;
}

.btnKioskoVolver {
  height: 70px !important;
  width: 150px !important;
  font-size: 3em !important;
  text-align: center;
}

.btnNumero {
  height: 70px !important;
  width: 80px !important;
  font-size: 3em !important;
  text-align: center;
  color: white;
  align-items: center;
}

.btnNavegar {
  height: 70px !important;
  width: 150px !important;
  font-size: 2em !important;
  text-align: center;
  border-style: none;
  border: none;
}
.btnImprimir {
  height: 70px !important;
  width: 100% !important;
  font-size: 2em !important;
  text-align: center;
  border-style: none;
  border: none;
}

.ant-notification .ant-notification-bottomLeft {
box-sizing: border-box;
padding: 0;
color: rgba(0,0,0,.85);
font-size: 14px;
font-variant: tabular-nums;
line-height: 1.5715;
list-style: none;
position: fixed !important;
z-index: 1010 !important;
margin: 0 24px 0 0;
margin-right: 0;
margin-left: 24px;
box-shadow: black;
}
.leaflet-container {
  width: 100%;
  height: 65vh;
}
/* resumen */
.label-resumen{
font-size: 15px;
}

#sidebar-overlay{
z-index: 0;
}

#coche {
	margin: 10% auto -25px;
	position: absolute;
	z-index: 3;
  /* left: 500px; */
  /* bottom: 60px; */
	width: 300px;
	-webkit-animation: car 50s infinite;
	        animation: car 50s infinite;
}

/*Animacion para el coche en funcionamiento*/
@-webkit-keyframes car {
 50% { -webkit-transform: translateX(10%); }
 70% { -webkit-transform: translateX(-20%); }
 100% { -webkit-transform: translateX(0%); }
}

@keyframes car {
 50% { transform: translateX(10%); }
 70% { transform: translateX(-20%); }
 100% { transform: translateX(0%); }
}

#coche img {
  width: 100%;
  -webkit-animation: suspension 1s infinite;
					animation: suspension 1s infinite;
}

/*Animacion para crear la suspension del coche*/
@-webkit-keyframes suspension {
	0% { -webkit-transform: translateY(1px); }
	50% { -webkit-transform: translateY(3px); }
	100% { -webkit-transform: translateY(1px); }
}

@keyframes suspension {
	0% { transform: translateY(1px); }
	50% { transform: translateY(3px); }
	100% { transform: translateY(1px); }
}

#rueda1 {
	background: url("https://i.imgsafe.org/t/ce046ee.png") no-repeat center center;
	background-size: 99% auto;
	display: block;
	width: 50px;
	height: 50px;
	position: absolute;
	left: 46px;
	bottom: 2.9px;
	z-index: 4;
	-webkit-animation: giro 1s linear infinite;
					animation: giro 1s linear infinite;
 }

 #rueda2 {
	background: url("https://i.imgsafe.org/t/ce046ee.png") no-repeat center center;
 	background-size: 99% auto;
 	display: block;
 	width: 50px;
 	height: 50px;
 	position: absolute;
 	right: 48.8px;
 	bottom: 2.9px;
	z-index: 4;
	-webkit-animation: giro 1s linear infinite;
					animation: giro 1s linear infinite;
 }
  /*Animacion para el grio de las ruedas*/
  @-webkit-keyframes giro {
 	 from { -webkit-transform: rotate(0deg); }
 	 to { -webkit-transform: rotate(-360deg); }
  }

  @keyframes giro {
 	 from { -webkit-transform: rotate(0deg); }
 	 to { -webkit-transform: rotate(-360deg); }
  }

 #arbol {
   width: 250px;
   height: 300px;
   position: absolute;
	 z-index: 1;
   bottom: 15px;
	 left: 10px;
   -webkit-animation: tree 20s linear infinite;
 	      	 animation: tree 20s linear infinite;
 }
 /*Animacion del arbol*/
 @-webkit-keyframes tree {
   from { left: 0; }
   to { left: 200%; }
 }

 @keyframes tree {
   from { left: 0; }
   to { left: 200%; }
 }

#suelo {
	border-top: 25px solid #333;
	height: 30px;
	background-color: green;
	background-size: 200px auto;
	position: relative;;
	z-index: 2;
	-webkit-animation: road 2s linear infinite;
	        animation: road 2s linear infinite;
}

 /*Animacion para la carretera*/
@-webkit-keyframes road {
	from { background-position: 0 0; }
	to { background-position: 200% 0; }
}

@keyframes road {
	from { background-position: 0 0; }
	to { background-position: 200% 0; }
}

.timeline-SAP{
  display: flex;
  justify-content: center;
}
.title-bascula{
  font-size: 15px;
    font-weight: 700;
}

.ant-timeline-item-tail{
  border-left:'2px solid #ffffff' !important
}
.bascula-title{
  font-size: 15px;
  font-weight: 500;
}

/* Estilos de calendario */
/* .ci_class{
  background-color: red !important;
} */
.ci_class.ci_class>div{
  background-color: green !important;
}
.ch_class.ch_class>div{
  background-color: #2C5282 !important;
}
.ci_class.fc-event.fc-event-dot{
  border: 2px solid green !important;
}
.ci_class.fc-event.fc-event-dot{
  border: 2px solid #2C5282 !important;
}

.ant-modal-title{
  /* font-size: 20px !important; */
  text-transform: uppercase;
}
.ant-form-item-required{
  font-size: 13px !important;
}
.ant-collapse-header{
  font-size: 15px;
}
/* FIN Estilos de calendario */

.ant-select-selection-search>input{
  text-transform: uppercase;
}

.status_circle{
  height: 10px;
  width: 10px;
  bottom: 0;
  position: absolute;
  border-radius: 50%;
  margin-left: 16px;
  margin-bottom: 5px;
  border: 1.5px solid white;
}



.cards {
	width: 100%;
	max-width: 95%;
	margin: 0 auto;
	padding: 15px;
}

.cards .card {
	margin: 15px;
	padding: 5px;
	background-color: #FFF;
	border-radius: 8px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.cards .card .card-header {
	position: relative;
	font-size: 20px;
	/* padding-right: 80px; */
	border-bottom: none;
	transition: all 0.4s ease;
}

.cards .card .card-header::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-50%);
	/* width: 30px;
	height: 30px; */

	transition: all 0.4s ease-out;
}

.cards .card .card-body {
	opacity: 0;
	max-height: 0;
	overflow-y: hidden;
	padding: 0 0 0 0;
	transition: all 0.4s ease;
}

.cards .card.open .card-header {
	margin-bottom: 15px;
}

.cards .card.open .card-header::after {
	transform: translateY(-50%) rotate(180deg);
}

.cards .card.open .card-body {
	max-height: 1000px;
	opacity: 1;
}

.my-card
{
    position:absolute;
    left:40%;
    top:-20px;
	border-radius:50%;
	border-color: black;
	border: solid 1px;	
}

.personalizado {
	border-radius:8%;
	box-shadow: #000;
	width: 100%;
	background-color: #F1C40F;
}

.card-counter{
    box-shadow: 2px 2px 10px #DADADA;
    margin: 5px;
    padding: 20px 10px;
    background-color: #fff;
    height: 100px;
    border-radius: 5px;
    transition: .3s linear all;
  }

  .card-counter:hover{
    box-shadow: 4px 4px 20px #DADADA;
    transition: .3s linear all;
  }

  .card-counter.primary{
    background-color: #007bff;
    color: #FFF;
  }

  .card-counter.danger{
    background-color: #ef5350;
    color: #FFF;
  }  

  .card-counter.success{
    background-color: #66bb6a;
    color: #FFF;
  }  

  .card-counter.info{
    background-color: #26c6da;
    color: #FFF;
  }  

  .card-counter i{
    font-size: 5em;
    opacity: 0.2;
  }

  .card-counter .count-numbers{
    position: absolute;
    right: 35px;
    top: 20px;
    font-size: 32px;
    display: block;
  }

  .card-counter .count-name{
    position: absolute;
    right: 35px;
    top: 65px;
    font-style: italic;
    text-transform: capitalize;
    opacity: 0.5;
    display: block;
    font-size: 18px;
  }

.circulo {
  width: 55px;
  height: 55px;
  border-radius: 55%;
  border: solid 3px;
  /* background: #5cb85c; */
  margin: 20px 45px 0px 0px;

  border-color: #5cb85c;
}
.contenedor{
  background: #EBF8FF;
  border-radius: 50%;
  height: 45px;
}
.number-step{
  padding: 30px 15px 0px 30px;
}
.nav-link{
  color: #667387 !important;
}

.arrow{
  margin: 10px;

}
.title-card{
  color:#2B6CB0;
  font-family: Poppins;
  font-weight: bold;

}
.circulo {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	background: #EBF8FF;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
  margin:0px auto;
  padding:3%;
  border:none;
  
}
.circulo2 {
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	background: #EB5757;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
  margin:0px auto;
  padding:3%;
  border:none;
  
}

.cantidad{
  padding-top: 15px;

}
.sub-titulo{
  font-size: 14px;
  color: #2B6CB0;
  font-family: Poppins;

}

.card-nomi{
  height: 128px;
}
  .card-nomi2{
    height: auto !important;
    width: auto !important;
  }

  .card-especificaciones{
    height: auto !important;
    width: auto !important;
  }

  .card-adicionales{
    height: auto !important;
    width: auto !important;
  }

.noti{
  border-radius: 25%;
    border: solid 1px;
    border-color: #c7c7c7;
}
.user{
  color: #fff;
  font-size: 16px;
    padding-top: 12px;
}

.due-pt{
  padding-top: 5rem !important;

}

.ant-tabs-tab{
  width: 225px;
  /* border-right: solid 1px #8a8a8a !important; */

}
.count{
  max-width: 23% !important;

}

.table-liquidacion{
  text-align: left !important;
}